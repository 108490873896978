<template lang="pug">
  div
    Header(:content="headerContent")
    div.wrap-calendar-meeting-settings
      div.pt20
        ModuleEditMember(ref="edit_member"
          @validateCurrentSettings="validateCurrentSettings")
        ModuleSelectMeetingRoomType(ref="select_meeting_room_type"
          @validateCurrentSettings="validateCurrentSettings")
        ModuleSetSummary(ref="set_summary"
          @validateCurrentSettings="validateCurrentSettings")
      div.wrap-send-btn.f.fh
        div(@click="startScheduling"
          :class="{'active': !preventNext}").btn-send.py16
          span.block.text-center.fz14 {{'日程調整を開始'}}
    ModalWindow(:showModal="showTutorialWindow"
      @closeModalWindow="showTutorialWindow = false")
      ModuleTutorialCreateMeeting(:availableSlotsText="availableSlotsText"
        @closeModalWindow="showTutorialWindow = false")
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';
.wrap-calendar-meeting-settings {
  width: 100%;
  min-height: 100vh;
  padding-top: $header_height;
  .wrap-send-btn {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 12px;
    // z-index: 1001;
    .btn-send {
      width: $base_width_percent;
      max-width: $base_max_width_px;
      background: $deactive_color;
      border-radius: 28px;
      filter: $drop_shadow;
      span {
        color: $dark_primary_text_color;
      }
      &.active {
        background: $active_color;
        cursor: pointer;
      }
    }
  }
}
</style>

<style lang="scss">
@import '@/scss/_variables.scss';
.wrap-calendar-meeting-settings {
  .set-summary-content {
    .v-input__slot {
      border-bottom: $basic_border;
    }
  }
}
</style>

<script>
import database from '@/database'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')
import Header from '@/components/shared/Header'
import ModalWindow from '@/components/shared/ModalWindow'
import ModuleSetSummary from '@/components/module/create-meeting/ModuleSetSummary'
import ModuleEditMember from '@/components/module/create-meeting/ModuleEditMember3'
import ModuleSelectMeetingRoomType from '@/components/module/create-meeting/ModuleSelectMeetingRoomType'
import ModuleTutorialCreateMeeting from '@/components/module/create-meeting/ModuleTutorialCreateMeeting'

export default {
  components: {
    Header,
    ModalWindow,
    ModuleSetSummary,
    ModuleEditMember,
    ModuleSelectMeetingRoomType,
    ModuleTutorialCreateMeeting
  },
  props: {
    selectMode: {
      type: String,
      required: true
    },
    calendarSlots: {
      type: Array,
      required: true
    },
    conditionSettings: {
      type: Object,
      required: true
    },
    newMeetingDocId: {
      type: String,
      required: true
    },
    availableSlotsText: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      headerContent: {
        title: 'ミーティングの設定',
        left: {
          icon: 'mdi-close',
          action: this.onClose
        }
      },
      user: null,
      showTutorialWindow: false,
      preventNext: true
    }
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  async created() {
    if (this.selectMode === 'start-adjusting-by-sending-available-slots') {
      this.user = await database.userCollection().findById(this.uid)

      if (!this.user.completeTutorialInCreateMeeting) {
        this.showTutorialWindow = true
      }

      this.$root.$emit('showSharedMessage', {
        message: '選択した空き日時がコピーされました！',
        icon: 'mdi-content-copy'
      })
    }
  },
  methods: {
    async startScheduling() {
      if (this.preventNext) return

      const setSummaryComponent = this.$refs.set_summary
      const editMemberComponent = this.$refs.edit_member
      const selectMeetinRoomType = this.$refs.select_meeting_room_type
      const member = editMemberComponent.getMember()
      if (!member[0]) {
        alert('メンバーが選択されていません')
        this.toggleMember()
        return
      }

      let meeting = {
        title: setSummaryComponent.title,
        about: setSummaryComponent.about,
        attendee: editMemberComponent.getMember().map((e) => {
          e.responsed = false
          return e
        }),
        // only for query of firestore array-contain
        attendeeEmail: editMemberComponent
          .getMember()
          .filter((e) => e.email && e.email !== '')
          .map((e) => e.email),
        duration: this.conditionSettings.duration,
        timeRange: this.conditionSettings.timeRange,
        dateRange: this.conditionSettings.dateRange,
        dayOfWeek: this.conditionSettings.dayOfWeek,
        startedAt: new Date(),
        status: 'adjusting',
        adjustmentPolicy: '01-unanimous',
        createdBy: this.uid,
        createdAt: new Date(),
        timeZone: 'Asia/Tokyo',
        sentNotiAfter24h: false,
        selectMeetinRoomType: selectMeetinRoomType.meetingURLType
      }

      // 自分を最後に追加
      meeting.attendee.push({
        name: this.user.name,
        email: this.user.email,
        telNo: '', // TO DO: 電話番号が設定されていたら追加する
        responsed: false
      })
      meeting.attendeeEmail.push(this.user.email)

      console.log('meeting:', { ...meeting })
      // const meetingDoc = await database.meetingCollection().add(meeting)
      await database.meetingCollection().set(this.newMeetingDocId, meeting)

      const slotsToSend = this.calendarSlots
        .filter((slot) => slot.isSelected)
        .map((slot) => {
          return {
            start: slot.start,
            end: slot.end
          }
        })

      console.log('startScheduling:', slotsToSend)
      const attendeeEvents = {
        // events,
        availableSlots: slotsToSend,
        createdBy: this.user.uid,
        email: this.user.email,
        authEmail: this.user.email,
        timeZone: Intl
          ? Intl.DateTimeFormat().resolvedOptions().timeZone
          : { offset: new Date().getTimezoneOffset() },
        createdAt: new Date()
      }
      console.log('attendeeEvents:', { ...attendeeEvents })

      await database
        .meetingCollection()
        .setAttendeeEvents(this.newMeetingDocId, this.user.email, attendeeEvents)

      // TO DO: email抜きの名前だけでもアドレスに追加するようにする
      const newContacts = editMemberComponent.getNewContacts()
      if (newContacts[0]) {
        for (let i = 0; i < newContacts.length; i++) {
          database.userCollection().addMemberToContact(this.uid, {
            name: newContacts[i].name,
            email: newContacts[i].email ? newContacts[i].email : '',
            telNo: newContacts[i].telNo ? newContacts[i].telNo : '',
            createdBy: this.uid,
            createdAt: new Date()
          })
        }
      }

      this.$router.replace(`/meeting/${this.newMeetingDocId}`)
    },
    validateCurrentSettings() {
      console.log('validateCurrentSettings:')
      const moduleSetSummary = this.$refs.set_summary
      const moduleEditMember = this.$refs.edit_member
      if (
        moduleEditMember.getMember()[0] &&
        moduleEditMember.allMemberIsValid() &&
        moduleSetSummary.title !== '' &&
        moduleSetSummary.title !== null
      ) {
        this.preventNext = false
      } else {
        this.preventNext = true
      }
    },
    onClose() {
      this.$emit('closeModalPage')
    }
  }
}
</script>
