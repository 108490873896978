<template lang="pug">
  div.wrap-module-create-meeting
    div.wrap-condition-templates.condition-content.mb10
      v-select(label="ミーティングの条件"
        v-model="currentConditionTemplate"
        :items="conditionTemplates"
        item-text="label").mb6
      span.condition-text.fz14.block.mb4 {{conditionText}}
      div(v-if="!showDetailSettings").f.flex-right
        div(@click="showDetailSettings = !showDetailSettings").f.fm
          v-icon(v-if="!showDetailSettings" color="#4285f4") mdi-chevron-down
          span.btn-adjust-condition.cursor-pointer.fz12 条件を微調整する
    div(v-show="showDetailSettings").create-meeting-content.condition-content.mb16
      div.wrap-duration.mb18
        v-select(v-model="duration"
          :items="[30, 60, 90, 120, 150, 180]"
          label="ミーティング時間"
          suffix="分")
      div.wrap-date.mb18
        //- v-select(label="候補日の範囲"
          v-model="dateRange"
          :items="dateRangeSelections")
        ItemDateRangePicker(ref="dateRangePicker"
          :dateRange="dateRange"
          @updateDateRange="updateDateRange")
      div.wrap-time.mb18
        ItemTimePicker(ref="timePicker"
          label="候補時間の範囲"
          :range="timeRange"
          @updateTimeRange="updateTimeRange")
      div.wrap-day-of-week.mb8
        span.label.block.mb8 曜日の候補条件
        div.f.flex-wrap
          span(v-for="item in dayOfWeek"
            @click="item.active = !item.active"
            :class="{'active': item.active}"
            :key="item.day").selection.fz14.px16.pt7.pb5.mr6.mb6 {{item.label}}
    div(v-show="$route.name === 'create-meeting'").wrap-way.condition-content
      div.wrap-radio.mb12
        span.block.label.fz12.mb4 空き時間の選択方法
        v-radio-group(v-model="generateSlotsWith")
          v-radio(label="Googleカレンダーの空き時間を参照する" value="google")
          v-radio(v-if="!!microsoftToken" label="Outlookカレンダーの空き時間を参照する" value="outlook")
          v-radio(label="手動で空きスロットを選択する" value="manual")
    //- div(v-if="conditionText").wrap-condition-text.condition-content
      span.fz14 {{conditionText}}
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.wrap-module-create-meeting {
  width: 100%;
  // min-height: 100vh;
  // padding-top: $header_height;
  .condition-content {
    width: $base_width_percent;
    max-width: $base_max_width_px;
    margin: 0 auto;
    .label {
      color: #777;
    }
    .condition-text {
      color: #777;
    }
  }
  .wrap-condition-templates {
    .btn-adjust-condition {
      color: $active_color;
    }
  }
  .create-meeting-content {
    .wrap-duration {
      width: 120px;
    }
    .wrap-date-range {
      width: 186px;
    }
    .wrap-day-of-week {
      .label {
        font-size: 12px;
        color: #777;
      }
      .selection {
        border: solid 0.6px #777;
        border-radius: 12px;
        color: #777;
        cursor: pointer;
        &.active {
          color: #fff;
          background: $active_color;
          border: solid 0.6px $active_color;
        }
      }
    }
  }
  .wrap-condition-text {
    span {
      color: $secondary_text_color;
    }
  }
}
</style>

<style lang="scss">
@import '@/scss/_variables.scss';
.wrap-module-create-meeting {
  .v-input--selection-controls {
    margin: 0;
  }
  .v-input__control {
    border-bottom: $basic_border;
  }
}
</style>

<script>
import { conditionTemplates } from './conditionTemplates'
import format from 'date-fns/format'
import { addDays, endOfDay } from 'date-fns'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
import ItemTimePicker from '@/components/item/ItemTimePicker2'
import ItemDateRangePicker from '@/components/item/ItemDateRangePicker'
import database from '@/database'

export default {
  components: {
    ItemTimePicker,
    ItemDateRangePicker
  },
  computed: {
    ...mapStateAuth(['uid']),
    // dateRangeSelections() {
    //   const today = new Date()
    //   let endDates = []
    //   endDates.push(endOfDay(addDays(today, 7 - 1)))
    //   endDates.push(endOfDay(addDays(today, 14 - 1)))
    //   endDates.push(endOfDay(addDays(today, 21 - 1)))

    //   return endDates.map((e, index) => {
    //     return {
    //       //  start: format(today, 'MM/dd'),
    //       //  end: format(e, 'MM/dd')
    //       text: `${format(today, 'MM/dd')} ~ ${format(e, 'MM/dd')} (${index + 1}週間)`,
    //       value: {
    //         start: today,
    //         end: e
    //       }
    //     }
    //   })
    // },
    conditionText() {
      let startDay, endDay
      if (this.dateRange.value) {
        startDay = format(this.dateRange.value.start, 'MM/dd')
        endDay = format(this.dateRange.value.end, 'MM/dd')
      } else {
        startDay = format(this.dateRange.start, 'MM/dd')
        endDay = format(this.dateRange.end, 'MM/dd')
      }

      const activeDays = this.dayOfWeek.filter((e) => e.active).map((e) => e.label)

      if (!activeDays[0]) {
        return '曜日が選択されていません'
      }

      let activeDaysLabel = activeDays.join('/')
      activeDaysLabel = activeDaysLabel === '月/火/水/木/金' ? '平日' : `${activeDaysLabel}曜日の`

      return (
        `${startDay}から${endDay}の間で、` +
        `${activeDaysLabel}` +
        `${this.timeRange.start}~${this.timeRange.end}の内、` +
        `${this.duration}分のミーティングが可能な時間帯を調整`
      )
    },
    conditionTemplates() {
      return conditionTemplates
    }
  },
  watch: {
    duration() {
      this.validate()
      this.setAvailableSlotsToCalendar()
    },
    dateRange() {
      this.validate()
      this.setAvailableSlotsToCalendar()
    },
    timeRange() {
      this.validate()
      this.setAvailableSlotsToCalendar()
    },
    dayOfWeek: {
      handler() {
        this.validate()
        this.setAvailableSlotsToCalendar()
      },
      deep: true
    },
    currentConditionTemplate() {
      const tmp = this.conditionTemplates.filter(
        (e) => e.label === this.currentConditionTemplate
      )[0]
      this.duration = tmp.duration
      this.timeRange = tmp.timeRange
      this.dayOfWeek = this.dayOfWeek.map((e) => {
        e.active = tmp.dayOfWeek.includes(e.day)
        return e
      })

      this.setAvailableSlotsToCalendar()
    }
  },
  data() {
    return {
      microsoftToken: null,
      generateSlotsWith: 'google',
      duration: 60,
      timeRange: {
        start: '10:00',
        end: '17:00'
      },
      dateRange: null,
      dayOfWeek: [
        { label: '月', day: 'Monday', active: true },
        { label: '火', day: 'Tuesday', active: true },
        { label: '水', day: 'Wednesday', active: true },
        { label: '木', day: 'Thursday', active: true },
        { label: '金', day: 'Friday', active: true },
        { label: '土', day: 'Saturday', active: false },
        { label: '日', day: 'Sunday', active: false }
      ],
      currentConditionTemplate: null,
      showDetailSettings: false
    }
  },
  async created() {
    // this.dateRange = this.dateRangeSelections[1]
    const today = new Date()
    this.dateRange = {
      start: today,
      end: endOfDay(addDays(today, 7 - 1))
    }
    this.currentConditionTemplate = this.conditionTemplates[0].label

    this.microsoftToken = await database.userCollection().getMicrosoftToken(this.uid)

    this.setAvailableSlotsToCalendar()
  },
  methods: {
    setAvailableSlotsToCalendar() {
      const condition = {
        dateRange: this.dateRange,
        dayOfWeek: this.dayOfWeek.filter((e) => e.active).map((e) => e.day),
        duration: this.duration,
        timeRange: this.timeRange
      }
      this.$root.$emit('setInstanceCondition', condition)
    },
    setDefaultConditionByMeeting(meetingToTakeOver) {
      const isSameConditionTmp = (meeting, tmp) => {
        return (
          meeting.duration === tmp.duration &&
          JSON.stringify(meeting.dayOfWeek) === JSON.stringify(tmp.dayOfWeek) &&
          JSON.stringify(meeting.timeRange) === JSON.stringify(tmp.timeRange)
        )
        // TO DO: startDateもコンディションに入れる
      }
      const sameConditionTmp = this.conditionTemplates.filter((conditionTmp) => {
        return isSameConditionTmp(meetingToTakeOver, conditionTmp)
      })[0]

      if (sameConditionTmp) {
        this.currentConditionTemplate = sameConditionTmp.label
      } else {
        this.showDetailSettings = true
        this.currentConditionTemplate = '🔧　カスタム'
        this.$nextTick(() => {
          this.duration = meetingToTakeOver.duration
          this.dayOfWeek = this.dayOfWeek.map((d) => {
            d.active = meetingToTakeOver.dayOfWeek.includes(d.day)
            return d
          })
          this.timeRange = meetingToTakeOver.timeRange
        })
      }
    },
    updateTimeRange(timeRange) {
      this.timeRange = timeRange
    },
    updateDateRange(dateRange) {
      this.dateRange = dateRange
    },
    validate() {
      this.$emit('validateCurrentSettings')
    }
  }
}
</script>
