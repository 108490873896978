<template lang="pug">
  div.wrap-module-calendar
    //- NowLoading(:show="showNowLoading")
    div(v-if="showNowLoading").wrap-save-line
      v-progress-linear(indeterminate)
    div.wrap-calendar-header.cta-bg.f.fm
      div(v-if="['month', '4day'].includes(viewType)").header-content.f.fm.flex-between
        div.f.flex-between
          v-icon(@click="prev") mdi-chevron-left
          span.pt1 {{headerLabel}}
          v-icon(@click="next") mdi-chevron-right
        div.f.fm
          //- div.wrap-slot-duration-settings.f.fm.mr6
            span.fz12 スロット時間：
            select(v-model="currentDuration").fz13
              option(v-for="d in [30, 60, 90, 120, 180]") {{d}}
          div(v-if="selectMode=='start-adjusting-by-sending-available-slots'").mr12
            span(v-if="!isSelectingAll" @click="selectAll").btn-select-all.fz14.bold 全選択
            span(v-else @click="unselectAll").btn-unselect-all.fz14.bold 全解除
          div
            v-icon(v-if="viewType == '4day'"
              @click="viewType = 'month'") mdi-calendar
            v-icon(v-if="viewType == 'month'"
              @click="viewType = '4day'") mdi-view-column
        div(v-if="viewType === 'day'").header-content.f.fm
          v-icon(@click="viewType = 'month'") mdi-chevron-left
          span.ml4.pt1 {{headerDayLabel}}

    div(:class="{'is-ios-safari': iOSSafari}").wrap-calendar
      v-calendar(
        ref="calendar"
        v-model="value"
        locale="ja"
        category-hide-dynamic
        :weekdays="weekday"
        :type="viewType"
        :events="calendarSlots"
        :event-overlap-mode="mode"
        :event-color="getEventColor"
        :event-overlap-threshold="30"
        :interval-format="intervalFormatter"
        :interval-minutes="intervalMinutes"
        :interval-count="intervalCount"
        @change="onChangeEvents"
        @click:date="clickDate"
        @click:event="clickEvent")
        template(v-slot:event="props")
          div(v-if="props.event.slotType === 'available-slot'"
            :class="getAvailableSlotClass(props.event)").available-slot
            span.block.fz10.pt2.px2.text-center {{ props.event.name }}
          div(v-if="props.event.slotType === 'event-slot'").event-slot
            div.f.fm
              v-icon(v-if="props.event.eventType === 'google'"
                size="10px" dark).pt2 mdi-google
              v-icon(v-if="props.event.eventType === 'microsoft'"
                size="10px" dark).pt2 mdi-microsoft
              v-icon(v-if="props.event.eventType === 'zoom'"
                size="10px" dark).pt2 mdi-video
              span.fz10.pt2.block.px2 {{ props.event.name }}
          div(v-if="props.event.slotType === 'meeting-slot'").meeting-slot
            span.fz10.pt2.block.px2 {{ props.event.name }}

      ItemCalendarChangeSelectMode(ref="selectModeChanger"
        v-if="conditionSettings"
        @setSelectMode="setSelectMode"
        @selectAll="selectAll"
        :selectMode="selectMode"
        :calendarSlots="calendarSlots" :conditionSettings="conditionSettings"
        :slotsToSend="slotsToSend")
      //- div(@click="onAddEvent").button-add-event.f.fh.px12.py12.cursor-pointer
        v-icon(color='#fff') add
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

$calendar_header_height: 36px;
$safari_toolbar_height: 110px;

.wrap-module-calendar {
  width: 100%;
  min-height: 100vh;
  padding-top: $header_height;
  .wrap-saving-line {
    position: fixed;
    top: $header_height;
    width: 100%;
  }
  .wrap-calendar-header {
    position: fixed;
    top: $header_height;
    width: 100%;
    height: $calendar_header_height;
    z-index: 1;
    .header-content {
      width: $base_width_percent;
      max-width: $base_max_width_px;
      margin: 0 auto;
      .btn-select-all,
      .btn-unselect-all {
        color: $secondary_text_color;
      }
      .wrap-slot-duration-settings {
        // border-bottom: $basic_border;
        select {
          outline: none;
          border-bottom: $basic_border;
        }
      }
      .selector {
        width: 68px;
        position: relative;
        top: 2px;
        select {
          outline: none;
        }
      }
    }
  }
  .wrap-calendar {
    position: relative;
    top: $calendar_header_height;
    height: calc(100vh - #{$header_height} - #{$calendar_header_height});
    // &.is-ios-safari {
    //   height: calc(100vh - #{$header_height} - #{$calendar_header_height} - #{$safari_toolbar_height});
    // }
    // .button-add-event {
    //   z-index: 1;
    //   position: absolute;
    //   width: 58px;
    //   height: 58px;
    //   right: 12px;
    //   bottom: 12px;
    //   border-radius: 50%;
    //   background: $active_color;
    //   filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.4));
    // }
  }
}
</style>

<style lang="scss">
@import '@/scss/_variables.scss';

.wrap-module-calendar {
  .available-slot {
    width: 100%;
    height: 100%;
    background: none;
    color: $deactive_color;
    border: dashed 1.8px $deactive_color;
    // border: dashed 1.8px $active_color;
    &.selectable {
      color: $active_color;
      border: dashed 1.8px $active_color;
    }
    &.selected {
      color: $dark_primary_text_color;
      border: none;
      background: $active_color;
    }
  }
  .event-slot {
    width: 100%;
    height: 100%;
    background: $secondary_text_color;
  }
  .meeting-slot {
    width: 100%;
    height: 100%;
    background: $deactive_color;
    // background: $active_color;
  }
  .v-event-timed-container {
    margin-right: 0;
  }
  .v-input__control {
    font-size: 14px;
  }
  .v-input__slot:before {
    display: none;
  }
  .v-input__slot:after {
    display: none;
  }
  .v-calendar-monthly {
    .v-event {
      strong {
        display: none;
      }
    }
  }
  .v-btn__content {
    color: #2a2a2a;
  }
}
</style>

<script>
import database from '@/database'
import { format, isEqual, addDays, addHours, startOfHour, differenceInMinutes } from 'date-fns'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
import Mixin from '@/components/mixin'
import NowLoading from '@/components/shared/NowLoading'
import ItemCalendarChangeSelectMode from '@/components/item/ItemCalendarChangeSelectMode'

export default {
  mixins: [Mixin],
  components: {
    NowLoading,
    ItemCalendarChangeSelectMode
  },
  props: {
    events: {
      type: Array,
      required: true
    },
    instanceConditionSettings: {
      type: Object,
      required: false
    },
    duration: {
      type: Number,
      default: 60
    },
    combinedDateRange: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapStateAuth(['uid']),
    calendarSlots() {
      const events = this.events.map((e) => {
        e.timed = e.isAlldayEvent ? false : true
        return {
          name: e.title,
          slotType: e.eventType === 'meeting' ? 'meeting-slot' : 'event-slot',
          ...e
        }
      })
      const availableSlots = this.availableSlots.map((e) => {
        return {
          // name: `${format(e.start, 'HH:mm')}~${format(e.end, 'HH:mm')}`,
          name: `${format(e.start, 'HH:mm')}`,
          timed: true,
          slotType: 'available-slot',
          ...e
        }
      })
      return [...events, ...availableSlots]
    },
    // slotsToSend () {
    //   return this.calendarSlots
    //     .filter(slot => slot.isSelected)
    //     .map(slot => {
    //       return {
    //         start: slot.start, end: slot.end
    //       }
    //     })
    // },
    intervalMinutes() {
      return [15, 30].includes(this.duration) ? 30 : 60
    },
    intervalCount() {
      return [15, 30].includes(this.duration) ? 24 * 2 : 24
    },
    iOSSafari() {
      const ua = window.navigator.userAgent
      const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i)
      const webkit = !!ua.match(/WebKit/i)
      return iOS && webkit && !ua.match(/CriOS/i)
    }
  },
  watch: {
    // currentDuration () {
    //   this.conditionSettings.duration = this.currentDuration
    //   this.availableSlots = this.generateAvailableSlots({
    //     condition: this.conditionSettings,
    //     events: this.events
    //   })
    //   console.log('this.availableSlots:', this.availableSlots)
    // },
    viewType(now) {
      if (now === '4day') setTimeout(this.scrollToRangeStart, 400)
    }
  },
  data() {
    return {
      userSettings: null,
      availableSlots: [],
      slotsToSend: [],
      conditionSettings: null,
      currentDuration: null,
      showNowLoading: false,
      selectMode: 'normal',
      // selectMode: 'start-adjusting-by-sending-available-slots',
      // calendar
      headerLabel: '',
      headerDayLabel: '',
      now: new Date(),
      viewType: '4day',
      viewTypes: ['month', 'week', 'day', '4day'],
      mode: 'stack',
      modes: ['stack', 'column'],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      weekdays: [
        { text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
        { text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] },
        { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
        { text: 'Mon, Wed, Fri', value: [1, 3, 5] }
      ],
      value: '',
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
      isSelectingAll: false
    }
  },
  async created() {
    this.initializeSlots()
  },
  mounted() {
    setTimeout(this.scrollToRangeStart, 400)
  },
  methods: {
    async initializeSlots() {
      this.showNowLoading = true

      this.availableSlots = []
      this.userSettings = await database.settingsCollection().findById(this.uid)
      this.conditionSettings =
        this.userSettings && this.userSettings.masterSlotsCondition
          ? this.userSettings.masterSlotsCondition
          : this.defaultAvaliableSlotConditionSettings
      this.conditionSettings.dateRange = this.combinedDateRange
      // this.conditionSettings.dateRange = {
      //   start: startOfHour(addHours(new Date(), 1)),
      //   end: addDays(new Date(), 7 * 5) // 5週間
      // }
      this.conditionSettings.duration = this.duration

      // ANDで条件テンプレートとコンディションテンプレートを掛け合わせていた時のコード
      // console.log('conditionSettings:', { ...this.conditionSettings })
      // if (this.instanceConditionSettings) {
      //   const self = this
      //   console.log('this.instanceConditionSettings:', { ...this.instanceConditionSettings })
      //   const newCondition = this.combineConditionAND ({
      //     masterCondition: this.conditionSettings,
      //     instanceCondition: this.instanceConditionSettings
      //   })
      //   if (newCondition) {
      //     this.conditionSettings = newCondition
      //   } else {
      //     alert('条件に一致する候補日がありません')
      //     this.availableSlots = []
      //     this.showNowLoading = false
      //     return
      //   }
      // }

      // this.currentDuration = this.conditionSettings.duration
      // this.availableSlots = this.generateAvailableSlots({
      //   condition: this.conditionSettings,
      //   events: this.events
      // })
      // console.log('this.availableSlots:', this.availableSlots)

      // console.log('conditionSettings:', { ...this.conditionSettings })
      if (this.instanceConditionSettings) {
        // console.log('this.instanceConditionSettings:', { ...this.instanceConditionSettings })
        const slotsFromInstanse = this.generateAvailableSlots({
          condition: this.instanceConditionSettings,
          events: this.events
        })
        const slotsFromMaster = this.generateAvailableSlots({
          condition: this.conditionSettings,
          events: this.events
        })
        // 条件テンプレートと生活時間で重複したスロットを削除する
        const additionalSlotsFromMasterCondition = slotsFromMaster.filter((slot) => {
          let isDupulicatedSlot = false
          for (let i = 0; i < slotsFromInstanse.length; i++) {
            if (
              isEqual(slotsFromInstanse[i].start, slot.start) &&
              isEqual(slotsFromInstanse[i].end, slot.end)
            ) {
              isDupulicatedSlot = true
            }
          }
          return !isDupulicatedSlot
        })

        this.availableSlots = [...slotsFromInstanse, ...additionalSlotsFromMasterCondition]
        // this.availableSlots = [...slotsFromMaster, ...additionalSlotsFromInstanceCondition]
      } else {
        this.currentDuration = this.conditionSettings.duration
        this.availableSlots = this.generateAvailableSlots({
          condition: this.conditionSettings,
          events: this.events
        })
      }

      if (!this.availableSlots[0]) {
        alert('条件に一致する候補日がありません')
        this.showNowLoading = false
        return
      }

      this.scrollToRangeStart()

      this.showNowLoading = false
    },
    onChangeEvents({ start }) {
      this.headerLabel = format(new Date(start.date), 'M月')
    },
    clickDate(e) {
      console.log('clickDate', e)
      if (this.viewType === 'month') {
        this.viewType = 'day'
        this.headerDayLabel = format(new Date(e.date), 'yyyy年M月d日')
      }
    },
    clickEvent(e) {
      if (e.event.slotType === 'meeting-slot') {
        this.$router.push(`/calendar/meeting/${e.event.id}`)
      }
      if (e.event.slotType === 'available-slot') {
        if (
          this.selectMode === 'start-adjusting-by-sending-available-slots' ||
          this.selectMode === 'start-adjusting-by-adding-people'
        ) {
          // available slotを選択済み状態にする
          // e.event.isSelected = true
          e.event.isSelected = e.event.isSelected ? false : true

          // TO DO: 直す
          const selectModeChanger = this.$refs.selectModeChanger
          selectModeChanger.updateSelectedSlotsNum()
          // リアクティブじゃないのでclassを変更するためにモードの値を更新している

          if (this.selectMode === 'start-adjusting-by-sending-available-slots') {
            this.setSelectMode('')
            this.setSelectMode('start-adjusting-by-sending-available-slots')
          }
          if (this.selectMode === 'start-adjusting-by-adding-people') {
            this.setSelectMode('')
            this.setSelectMode('start-adjusting-by-adding-people')
          }

          this.slotsToSend = this.calendarSlots
            .filter((slot) => slot.isSelected)
            .map((slot) => {
              return {
                start: slot.start,
                end: slot.end
              }
            })
        }
        if (this.selectMode === 'create-adjusted-meeting') {
          const start = e.event.start
          const end = e.event.end
          const duration = differenceInMinutes(end, start)
          const startStr = format(start, 'yyyy-MM-dd HH:mm')
          const path = `/create-adjusted-meeting/${startStr}/${String(duration)}`
          this.$router.push(path)
        }
      }
    },
    async sendAvailableSlots() {},
    setSelectMode(selectMode) {
      this.selectMode = selectMode
      this.$emit('setHeaderMode', selectMode)

      if (selectMode === 'normal') {
        this.availableSlots = this.availableSlots.map((slot) => {
          if (slot.isSelected) slot.isSelected = false
          return slot
        })
      }
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    getEventColor(event) {
      return event.color
    },
    intervalFormatter(event) {
      return event.time
    },
    onAddEvent() {
      const event = this.calendarSlots.filter((c) => c.slotType === 'available-slot')[0]
      const start = event.start
      const end = event.end
      const duration = differenceInMinutes(end, start)
      const startStr = format(start, 'yyyy-MM-dd HH:mm')
      const path = `/create-adjusted-meeting/${startStr}/${String(duration)}`
      this.$router.push(path)
    },
    scrollToRangeStart() {
      // 対象の範囲の時間まで強制スクロール
      const intervals = document.getElementsByClassName('v-calendar-daily__interval')
      let targetElem
      const targetHourLabel = this.availableSlots[0]
        ? format(this.availableSlots[0].start, 'HH')
        : '10'
      for (let i = 0; i < intervals.length; i++) {
        if (intervals[i].innerText.split(':')[0] === targetHourLabel) {
          targetElem = intervals[i]
          break
        }
      }
      // const calendarHeader = document.getElementsByClassName('v-calendar-daily__head')[0]
      const scrollArea = document.getElementsByClassName('v-calendar-daily__scroll-area')[0]
      if (scrollArea) scrollArea.scrollTo(0, targetElem.offsetTop - 10)
    },
    getAvailableSlotClass(event) {
      if (event.isSelected) {
        return 'selected'
      }
      if (this.selectMode !== 'normal') {
        return 'selectable'
      }
    },
    selectAll() {
      this.availableSlots = this.availableSlots.map((slot) => {
        slot.isSelected = true
        return slot
      })

      const selectModeChanger = this.$refs.selectModeChanger
      selectModeChanger.updateSelectedSlotsNum()

      this.isSelectingAll = true
    },
    unselectAll() {
      this.availableSlots = this.availableSlots.map((slot) => {
        slot.isSelected = false
        return slot
      })

      const selectModeChanger = this.$refs.selectModeChanger
      selectModeChanger.updateSelectedSlotsNum()

      this.isSelectingAll = false
    }
  }
}
</script>
