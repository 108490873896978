<template lang="pug">
  div.wrap-module-set-master-slot
    div.set-master-slot-content.py12
      //- div.wrap-condition-text.mb16
        span.fz12 {{conditionText}}
      //- div.wrap-duration.mb8
        v-select(v-model="duration"
          :items="[30, 60, 90, 120, 150, 180]"
          label="デフォルト時間"
          suffix="分")
      div.wrap-time.mb18
        ItemTimePicker(ref="timePicker"
          label="活動時間の範囲"
          :range="timeRange"
          @updateTimeRange="updateTimeRange")
      div.wrap-day-of-week.mb8
        span.label.block.mb8 活動曜日
        div.f.flex-wrap
          span(v-for="item in dayOfWeek"
            @click="item.active = !item.active"
            :class="{'active': item.active}"
            :key="item.day").selection.fz14.px16.pt7.pb5.mr6.mb6 {{item.label}}
      //- div.wrap-save.f.flex-right
        span(@click="onSave").btn-save.fz14.px12.py8.cursor-pointer 保存する
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.wrap-module-set-master-slot {
  width: 100%;
  // padding-top: $header_height;
  .set-master-slot-content {
    width: $base_width_percent;
    max-width: $base_max_width_px;
    margin: 0 auto;
    .wrap-duration {
      width: 120px;
    }
    .wrap-date-range {
      width: 186px;
    }
    .wrap-day-of-week {
      .label {
        font-size: 12px;
        color: #777;
      }
      .selection {
        border: solid 0.6px #777;
        border-radius: 12px;
        color: #777;
        cursor: pointer;
        &.active {
          color: #fff;
          background: $active_color;
          border: solid 0.6px $active_color;
        }
      }
    }
    .btn-save {
      background: $active_color;
      color: $dark_primary_text_color;
      border-radius: 3px;
    }
  }
}
</style>

<script>
import database from '@/database'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
import Mixin from '@/components/mixin'
import ItemTimePicker from '@/components/item/ItemTimePicker2'

export default {
  mixins: [Mixin],
  components: {
    ItemTimePicker
  },
  computed: {
    ...mapStateAuth(['uid']),
    conditionText() {
      let activeDayOfWeekLabel = this.dayOfWeek
        .filter((d) => d.active)
        .map((d) => d.label)
        .join('/')
      if (activeDayOfWeekLabel === '月/火/水/木/金') activeDayOfWeekLabel = '平日'
      const timeRangeLabel = `${this.timeRange.start}~${this.timeRange.end}`
      return `${activeDayOfWeekLabel}の${timeRangeLabel}から${this.duration}分の空きスロットを作成`
    }
  },
  data() {
    return {
      conditionSettings: null,
      duration: 60,
      timeRange: {
        start: '10:00',
        end: '17:00'
      },
      dayOfWeek: [
        { label: '月', day: 'Monday', active: true },
        { label: '火', day: 'Tuesday', active: true },
        { label: '水', day: 'Wednesday', active: true },
        { label: '木', day: 'Thursday', active: true },
        { label: '金', day: 'Friday', active: true },
        { label: '土', day: 'Saturday', active: false },
        { label: '日', day: 'Sunday', active: false }
      ]
    }
  },
  async created() {
    const userSettings = await database.settingsCollection().findById(this.uid)

    this.conditionSettings = userSettings.masterSlotsCondition
      ? userSettings.masterSlotsCondition
      : this.defaultAvaliableSlotConditionSettings

    this.duration = this.conditionSettings.duration
    this.timeRange = this.conditionSettings.timeRange
    this.dayOfWeek = this.dayOfWeek.map((d) => {
      d.active = this.conditionSettings.dayOfWeek.includes(d.day)
      return d
    })
  },
  methods: {
    updateTimeRange(timeRange) {
      this.timeRange = timeRange
    },
    async onSave() {
      const condition = {
        // duration: this.duration,
        timeRange: this.timeRange,
        dayOfWeek: this.dayOfWeek.filter((d) => d.active).map((d) => d.day)
      }
      console.log('condition:', condition)
      await database.settingsCollection().update(this.uid, { masterSlotsCondition: condition })

      // this.$emit('completeNewConditionSettings', condition)
    }
  }
}
</script>
