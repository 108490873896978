<template lang="pug">
  Auth(@loggedIn="onLoggedIn" :redirectWithLoginFailed="true")
    Header(:content="headerContent")
    DrawerMenu(v-if="uid"
      :uid="uid" :show="showMenu" @close="toggleMenu")
    div.wrap-calendar
      //- master slot condition
      transition(name="slidedown")
        div(v-show="showSettings").wrap-sub-menu.pt4
          div.wrap-duration.pt12
            div.duration.wrap-content
              div.selector
                v-select(v-model="duration"
                  :items="[15, 30, 60, 90, 120, 180]"
                  label="ミーティング時間"
                  suffix="分")
          div.wrap-calendar-event-checkbox
            div.wrap-content
              span.label.fz14 イベント
              v-checkbox(v-model="useGoogleEvents" label="Googleカレンダー")
          ModuleSetCalendarCondition(
            ref="set_condition"
            @completeNewConditionSettings="completeNewConditionSettings"
            @validateCurrentSettings="validateCurrentSettings")
          ModuleSetMasterSlotsCondition(ref="set_master_condition"
            @completeNewConditionSettings="completeNewConditionSettings")
        //- div(v-show="showSettings").wrap-sub-menu.pt4
          ModuleSetMasterSlotsCondition(v-show="headerMode == 'normal'"
            @completeNewConditionSettings="completeNewConditionSettings")
          ModuleSetCalendarCondition(v-if="headerMode == 'start-adjusting-by-sending-available-slots'"
            ref="set_condition"
            @completeNewConditionSettings="completeNewConditionSettings"
            @validateCurrentSettings="validateCurrentSettings")
      transition(name="fade")
        div(v-if="showSettings" @click="toggleSettings").overlay.corsor-pointer

      ModuleCalendar(ref="moduleCalendar"
        v-if="events && uid && combinedDateRange"
        :events="events"
        :instanceConditionSettings="instanceConditionSettings"
        :duration="duration"
        :combinedDateRange="combinedDateRange"
        @setHeaderMode="setHeaderMode")
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.wrap-calendar {
  position: relative;
  width: 100%;
  min-height: 100vh;
  .wrap-content {
    width: $base_width_percent;
    max-width: $base_max_width_px;
    margin: 0 auto;
    .label {
      color: $secondary_text_color;
    }
    .selector {
      width: 120px;
    }
  }

  .slidedown-enter-active,
  .slidedown-leave-active {
    transition: all 0.2s ease-out;
    opacity: 1;
    transform: translateY(0%);
  }
  .slidedown-enter,
  .slidedown-leave-to {
    opacity: 0;
    transform: translateY(-100%);
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.2s ease-out;
    opacity: 1;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  .wrap-sub-menu {
    top: $header_height;
    width: 100%;
    position: absolute;
    z-index: 10;
    background: $primary_bg_color;
  }
  .overlay {
    top: $header_height;
    width: 100%;
    height: calc(100% - #{$header_height});
    background: $overlay_color;
    position: absolute;
    z-index: 9;
  }
}
</style>

<style lang="scss">
.wrap-calendar-event-checkbox {
  .v-input--selection-controls {
    margin: 0;
  }
  .v-messages {
    display: none;
  }
}
</style>

<script>
import database from '@/database'
import { addDays, addHours, startOfHour } from 'date-fns'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
import Auth from '@/components/shared/Auth'
import Header from '@/components/shared/Header'
import DrawerMenu from '@/components/shared/DrawerMenu'
import ModuleCalendar from '@/components/module/ModuleCalendar'
import ModuleSetMasterSlotsCondition from '@/components/module/ModuleSetMasterSlotsCondition'
import ModuleSetCalendarCondition from '@/components/module/create-meeting/ModuleSetCalendarCondition'
import Mixin from '@/components/mixin'

// import firestore from '@/components/utils/firebase'
// import { isEqual } from 'date-fns'

export default {
  mixins: [Mixin],
  components: {
    Auth,
    Header,
    DrawerMenu,
    ModuleSetMasterSlotsCondition,
    ModuleSetCalendarCondition,
    ModuleCalendar
  },
  data() {
    return {
      headerContent: {
        title: '空き予定',
        left: {
          icon: 'mdi-menu',
          action: this.toggleMenu
        },
        right: {
          icon: 'mdi-tune',
          action: this.toggleSettings
        }
      },
      createAdjustedMeetingHeaderContent: {
        title: '空きスロットを選択',
        left: {
          icon: 'mdi-close',
          action: this.onCancelSelectMode
        }
        // right: {
        //   icon: 'mdi-cog',
        //   action: this.toggleSettings
        // }
      },
      headerMode: 'normal',
      showMenu: false,
      showSettings: false,
      showMember: false,
      showSummary: false,
      showConditionSettings: false,
      // ---
      user: null,
      events: null,
      allEvents: null,
      instanceConditionSettings: null,
      duration: 60,
      useGoogleEvents: true,
      combinedDateRange: null,
      defaultDateRange: {
        start: startOfHour(addHours(new Date(), 1)),
        end: addDays(new Date(), 7 * 5) // 5週間
      }
    }
  },
  computed: {
    ...mapStateAuth(['accessToken', 'uid'])
  },
  mounted() {
    const moduleSetCalendarCondition = this.$refs.set_condition
    const instanceDateRange = moduleSetCalendarCondition.dateRange.value
      ? moduleSetCalendarCondition.dateRange.value
      : moduleSetCalendarCondition.dateRange
    this.combinedDateRange = this.combineDateRangeCondition(
      this.defaultDateRange,
      instanceDateRange
    )

    this.$root.$on('setInstanceCondition', this.setInstanceCondition)
  },
  methods: {
    async onLoggedIn() {
      this.user = await database.userCollection().findById(this.uid)
      // this.allEvents = []
      this.allEvents = await this.getAllEvents({
        uid: this.uid,
        email: this.user.email,
        accessToken: this.accessToken
      })
      this.events = this.allEvents
    },
    async completeNewConditionSettings() {
      this.toggleSettings()
      // await this.$refs.set_master_condition.onSave()
      const moduleCalendar = this.$refs.moduleCalendar
      moduleCalendar.initializeSlots()
    },
    toggleSettings() {
      this.showSettings = !this.showSettings
      if (this.showSettings) {
        this.headerContent.title = '空き時間の設定'
        this.headerContent.right = {
          // icon: 'mdi-close',
          label: 'OK',
          color: this.pallete.$active_color,
          action: this.updateInstanceConditionSettings // this.toggleSettings
        }
      } else {
        this.headerContent.right = {
          // icon: 'mdi-close',
          icon: 'mdi-tune',
          action: this.toggleSettings // this.toggleSettings
        }
      }
    },
    toggleMenu() {
      this.showMenu = !this.showMenu
    },
    onCancelSelectMode() {
      this.headerMode = 'normal'
      const moduleCalendar = this.$refs.moduleCalendar
      moduleCalendar.setSelectMode('normal')
      // moduleCalendar.selectMode = 'normal'
    },
    setHeaderMode(mode) {
      this.headerMode = mode
      if (this.headerMode === 'normal') {
        this.headerContent.left = {
          icon: 'mdi-menu',
          action: this.toggleMenu
        }
      } else if (this.headerMode === 'start-adjusting-by-sending-available-slots') {
        this.headerContent.left = {
          icon: 'mdi-close',
          action: this.onCancelSelectMode
        }
      } else if (this.headerMode === 'start-adjusting-by-adding-people') {
        this.headerContent.left = {
          icon: 'mdi-close',
          action: this.onCancelSelectMode
        }
      }
    },
    async updateInstanceConditionSettings() {
      const moduleSetMasterCondition = this.$refs.set_master_condition
      await moduleSetMasterCondition.onSave()

      const moduleSetCalendarCondition = this.$refs.set_condition
      if (moduleSetCalendarCondition) {
        const instanceDateRange = moduleSetCalendarCondition.dateRange.value
          ? moduleSetCalendarCondition.dateRange.value
          : moduleSetCalendarCondition.dateRange
        this.combinedDateRange = this.combineDateRangeCondition(
          this.defaultDateRange,
          instanceDateRange
        )

        this.instanceConditionSettings = {
          dateRange: this.combinedDateRange,
          dayOfWeek: moduleSetCalendarCondition.dayOfWeek.filter((e) => e.active).map((e) => e.day),
          duration: this.duration,
          timeRange: moduleSetCalendarCondition.timeRange
        }
      }

      this.events = this.allEvents
      if (!this.useGoogleEvents) {
        this.events = this.events.filter((a) => a.eventType !== 'google')
      }

      const moduleCalendar = this.$refs.moduleCalendar
      moduleCalendar.initializeSlots()

      this.toggleSettings()
    },
    setInstanceCondition(condition) {
      this.instanceConditionSettings = condition
      const moduleCalendar = this.$refs.moduleCalendar
      moduleCalendar.initializeSlots()
    },
    validateCurrentSettings() {
      // const moduleSetSummary = this.$refs.set_summary
      // const moduleEditMember = this.$refs.edit_member
      // const moduleSetCalendarCondition = this.$refs.set_condition
      // if (
      //   moduleEditMember.getMember()[0] &&
      //   moduleEditMember.allMemberIsValid() &&
      //   moduleSetSummary.title !== '' &&
      //   moduleSetSummary.title !== null &&
      //   moduleSetCalendarCondition.duration > 0 &&
      //   moduleSetCalendarCondition.dayOfWeek.filter((e) => e.active)[0] &&
      //   (!moduleSetCalendarCondition.$refs.dateRangePicker ||
      //     !moduleSetCalendarCondition.$refs.dateRangePicker.isInvalidRange) &&
      //   (!moduleSetCalendarCondition.$refs.timePicker ||
      //     !moduleSetCalendarCondition.$refs.timePicker.isInvalidRange)
      // ) {
      //   this.preventNext = false
      // } else {
      //   this.preventNext = true
      // }
    },
    setSummary({ title, about }) {
      const moduleSetSummary = this.$refs.set_summary
      moduleSetSummary.title = title
      moduleSetSummary.about = about
    }
  }
}
</script>
