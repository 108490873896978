<template lang="pug">
  div.wrap-module-tutorial-create-meeting.px16.pt16.pb10
    div.btn-close
      v-icon(@click="onNext") mdi-close
    div.f.fc.mb20
      div.f.fm
        v-icon.mr6 mdi-lightbulb
        span.block.bold.fz16.pt2 次のステップ
    div.mb18
      div.f.fm.mb8
        v-icon.mr6 mdi-account-multiple
        span.block.bold.fz14 参加者を追加しましょう！
      span.block.fz14 Emailもしくは電話番号を入力して送信するだけで、あとは自動で日時の調整が行われます。
    div.mb10
      div.f.fm.mb8
        v-icon.mr6 mdi-content-copy
        span.block.bold.fz14 連絡先を知らない場合は...
      span.block.fz14 選択した空き予定と招待URLがコピーされています。参加者の名前を入力して、LINE, Slack, Messengerなどでシェアしましょう。
    div
      //- span(@click="onNext").btn-ok.block.fz18.text-center.cursor-pointer 参加者を追加する
      div.f.fc
        v-checkbox(v-model="preventShowNext" label="次回から表示しない")
</template>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

.wrap-module-tutorial-create-meeting {
  position: relative;
  background: $primary_bg_color;
  border-radius: 3px;
  .btn-close {
    position: absolute;
    top: 12px; right: 12px;
  }
  // .btn-ok {
  //   color: $active_color;
  // }
}
</style>

<script>
import database from '@/database'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')

export default {
  components: {
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  data () {
    return {
      preventShowNext: false
    }
  },
  methods: {
    onNext () {
      if (this.preventShowNext) {
        database.userCollection().update(this.uid, {
          completeTutorialInCreateMeeting: true
        })
      }
      this.$emit('closeModalWindow')
    }
  }
}
</script>
