<template lang="pug">
  div.wrap-item-calendar-change-select-mode
    div(v-if="selectMode === 'normal'").mode-normal
      div(@click.stop="onParentFAB"
        :class="{'show-list': showList}").float-action-btn.f.fh.px12.py12.cursor-pointer
        v-icon(size="28px" dark) mdi-plus
      div.wrap-mode-list
        div(v-for="(mode, index) in selectModes"
          :style="`bottom: ${getBottomMarginOfChildIcon(index)}px;`"
          :class="{'active': showList}"
          @click.stop="onChildIcon(mode.name)").mode.f.fm.flex-right.cursor-pointer
          span.mr12 {{mode.label}}
          div.wrap-icon.f.fh.flex-right.px10.py10
            v-icon(size="24px" dark) {{mode.icon}}
      transition(name="overlay")
        div(v-if="showList"
          @click="showList = false").overlay.cursor-pointer

    div(v-if="selectMode === 'start-adjusting-by-sending-available-slots'")
      div.wrap-send-btn.f.fh
        div(@click="onStart"
          v-clipboard:copy="availableSlotsText"
          v-clipboard:success="onCopy"
          :class="{'active': slotsNum > 0}").btn-send.py16
          span.block.text-center.fz14 {{`${this.slotsNum}件の空き時間を設定`}}
    div(v-if="selectMode === 'start-adjusting-by-adding-people'")
      div.wrap-send-btn.f.fh
        div(@click="startSchedulingByAddingMember"
          :class="{'active': slotsNum > 0}").btn-send.py16
          span.block.text-center.fz14 {{`${this.slotsNum}件の空き時間で調整開始`}}

    ModalPage(:showModalPage="showModalPage")
      CalendarMeetingSettings(v-if="selectMode === 'start-adjusting-by-sending-available-slots' && showModalPage"
        @closeModalPage="showModalPage=false"
        :selectMode="selectMode"
        :calendarSlots="calendarSlots" :conditionSettings="conditionSettings"
        :newMeetingDocId="newMeetingDocId" :availableSlotsText="availableSlotsText")
      CalendarAdjustingByMember(ref="calendarAdjustingByMember"
        v-if="selectMode === 'start-adjusting-by-adding-people' && showModalPage"
        @closeModalPage="showModalPage=false"
        :selectMode="selectMode"
        :calendarSlots="calendarSlots" :conditionSettings="conditionSettings"
        :newMeetingDocId="newMeetingDocId" :availableSlotsText="availableSlotsText")
    //- div(v-if="selectMode === 'create-adjusted-meeting'")
      div.wrap-send-btn.f.fh
        div(@click="createAdjustedMeeting"
          :class="{'active': slotsNum > 0}").btn-send.py16
          span.block.text-center.fz14 ミーティングを追加
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';
$float_action_btn_size: 58px;
.wrap-item-calendar-change-select-mode {
  .mode-normal {
    .float-action-btn {
      position: fixed;
      width: $float_action_btn_size;
      height: $float_action_btn_size;
      right: 12px;
      bottom: 12px;
      z-index: 1002;
      border-radius: 50%;
      filter: $drop_shadow;

      transition: all ease-in-out 0.2s;
      transform: rotate(0deg);
      background: $active_color;
      // pointer-events: none;
      &.show-list {
        transform: rotate(45deg);
        background: $deactive_color;
      }
    }
    .wrap-mode-list {
      position: fixed;
      right: 20px;
      bottom: calc(#{$float_action_btn_size} + 12px);
      z-index: 1001;
      .mode {
        position: fixed;
        right: 19px;
        bottom: calc(#{$float_action_btn_size} + 12px);
        .wrap-icon {
          border-radius: 50%;
          background: $active_color;
          filter: $drop_shadow;
        }

        transition: all ease-in-out 0.2s;
        opacity: 0;
        &.active {
          opacity: 1;
        }
      }
    }
    .overlay {
      position: fixed;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 1000;
      background: rgba(255, 255, 255, 0.7);
    }
    .overlay-leave-active,
    .overlay-enter-active {
      transition: opacity ease-in-out 0.2s;
    }
    .overlay-enter,
    .overlay-leave-to {
      opacity: 0;
    }
    .overlay-enter-to,
    .overlay-leave {
      opacity: 1;
    }
  }

  .wrap-send-btn {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 12px;
    // z-index: 1001;
    .btn-send {
      width: $base_width_percent;
      max-width: $base_max_width_px;
      background: $deactive_color;
      border-radius: 28px;
      filter: $drop_shadow;
      span {
        color: $dark_primary_text_color;
      }
      &.active {
        background: $active_color;
        cursor: pointer;
      }
    }
  }
}
</style>

<script>
import database from '@/database'
import { domain } from '@/components/utils/config'
import { format } from 'date-fns'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
import ModalPage from '@/components/shared/ModalPage'
import CalendarMeetingSettings from '@/views/CalendarMeetingSettings'
import CalendarAdjustingByMember from '@/views/CalendarAdjustingByMember'

export default {
  components: {
    ModalPage,
    CalendarMeetingSettings,
    CalendarAdjustingByMember
  },
  props: {
    selectMode: {
      type: String,
      required: true
    },
    calendarSlots: {
      type: Array,
      required: true
    },
    slotsToSend: {
      type: Array,
      required: true
    },
    conditionSettings: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showList: false,
      selectModes: [
        {
          name: 'start-adjusting-by-sending-available-slots',
          icon: 'mdi-expand-all-outline',
          label: '空きスロットを選択して日時を調整'
        },
        {
          name: 'start-adjusting-by-adding-people',
          icon: 'mdi-account-group',
          label: '参加者を選択して自動で調整する'
        },
        {
          name: 'create-adjusted-meeting',
          icon: 'mdi-card-plus',
          label: '調整済みのミーティングを追加'
        }
      ],
      slotsNum: 0,
      newMeetingDocId: '',
      showModalPage: false,
      user: null
    }
  },
  computed: {
    ...mapStateAuth(['uid']),
    // slotsToSend () {
    //   return this.calendarSlots
    //     .filter(slot => slot.isSelected)
    //     .map(slot => {
    //       return {
    //         start: slot.start, end: slot.end
    //       }
    //     })
    // },
    availableSlotsText() {
      let slotTexts = []
      for (let i = 0; i < this.slotsToSend.length; i++) {
        const dateStr = format(this.slotsToSend[i].start, 'L/d')
        const startStr = format(this.slotsToSend[i].start, 'HH:mm')
        const endStr = format(this.slotsToSend[i].end, 'HH:mm')
        const dayWeekStr = format(this.slotsToSend[i].start, 'iii')
        let text
        if (
          slotTexts[slotTexts.length - 1] &&
          slotTexts[slotTexts.length - 1].dateStr === dateStr
        ) {
          text = ` ${startStr} ~ ${endStr}`
        } else {
          text = `${dateStr}(${dayWeekStr})\n ${startStr} ~ ${endStr}`
        }
        slotTexts.push({ text, dateStr })
      }
      const availableSlotsText = slotTexts.map((s) => s.text).join('\n')
      const invitationText = `招待URL：${domain}/invitation/lp/${this.newMeetingDocId}`
      return `${availableSlotsText} \n\n ${invitationText}`
    }
    // selectedSlots () {
    //   return this.calendarSlots.filter(slot => slot.isSelected)
    // }
  },
  // watch: {
  //   calendarSlots: {
  //     handler () {
  //       debugger
  //     },
  //     deep: true
  //   }
  // },
  async created() {
    this.newMeetingDocId = database.createDocId()
    this.user = await database.userCollection().findById(this.uid)
    // for dev
    // this.$emit('setSelectMode', 'start-adjusting-by-sending-available-slots')
  },
  mounted() {
    if (this.$route.params.defaultMembers) {
      this.onChildIcon('start-adjusting-by-adding-people')
    }
  },
  methods: {
    onParentFAB() {
      this.showList = !this.showList
      // if (!this.showList) this.$emit('setSelectMode', 'normal')
    },
    getBottomMarginOfChildIcon(index) {
      if (this.showList) {
        const parentSize = 28
        const baseMargin = 54
        return parentSize + (this.selectModes.length - index) * baseMargin
      } else {
        return 0
      }
    },
    onChildIcon(modeName) {
      this.$emit('setSelectMode', modeName)
      this.showList = false

      if (modeName === 'start-adjusting-by-adding-people') {
        this.showModalPage = true
        // this.$emit('setSelectMode', 'select-available-slots-by-adding-people')
        // this.$emit('selectAll')
      }
    },
    updateSelectedSlotsNum() {
      this.slotsNum = this.calendarSlots.filter((slot) => slot.isSelected).length
    },
    onStart() {
      if (this.slotsNum > 0) this.showModalPage = true
    },
    async startSchedulingByAddingMember() {
      const calendarAdjustingByMember = this.$refs.calendarAdjustingByMember
      const setSummaryComponent = calendarAdjustingByMember.$refs.set_summary
      const editMemberComponent = calendarAdjustingByMember.$refs.edit_member
      const selectMeetinRoomType = calendarAdjustingByMember.$refs.select_meeting_room_type
      const member = editMemberComponent.getMember()
      if (!member[0]) {
        alert('メンバーが選択されていません')
        this.toggleMember()
        return
      }

      let meeting = {
        title: setSummaryComponent.title,
        about: setSummaryComponent.about,
        attendee: editMemberComponent.getMember().map((e) => {
          e.responsed = false
          return e
        }),
        // only for query of firestore array-contain
        attendeeEmail: editMemberComponent
          .getMember()
          .filter((e) => e.email && e.email !== '')
          .map((e) => e.email),
        duration: this.conditionSettings.duration,
        timeRange: this.conditionSettings.timeRange,
        dateRange: this.conditionSettings.dateRange,
        dayOfWeek: this.conditionSettings.dayOfWeek,
        startedAt: new Date(),
        status: 'adjusting',
        adjustmentPolicy: '01-unanimous',
        createdBy: this.uid,
        createdAt: new Date(),
        timeZone: 'Asia/Tokyo',
        sentNotiAfter24h: false,
        selectMeetinRoomType: selectMeetinRoomType.meetingURLType
      }

      // 自分を最後に追加
      meeting.attendee.push({
        name: this.user.name,
        email: this.user.email,
        telNo: '', // TO DO: 電話番号が設定されていたら追加する
        responsed: false
      })
      meeting.attendeeEmail.push(this.user.email)

      console.log('meeting:', { ...meeting })
      // const meetingDoc = await database.meetingCollection().add(meeting)
      await database.meetingCollection().set(this.newMeetingDocId, meeting)

      const slotsToSend = this.calendarSlots
        .filter((slot) => slot.isSelected)
        .map((slot) => {
          return {
            start: slot.start,
            end: slot.end
          }
        })

      console.log('startScheduling:', slotsToSend)
      const attendeeEvents = {
        // events,
        availableSlots: slotsToSend,
        createdBy: this.user.uid,
        email: this.user.email,
        authEmail: this.user.email,
        timeZone: Intl
          ? Intl.DateTimeFormat().resolvedOptions().timeZone
          : { offset: new Date().getTimezoneOffset() },
        createdAt: new Date()
      }
      console.log('attendeeEvents:', { ...attendeeEvents })

      await database
        .meetingCollection()
        .setAttendeeEvents(this.newMeetingDocId, this.user.email, attendeeEvents)

      this.$router.push(`/meeting/${this.newMeetingDocId}`)
    },
    onCopy() {
      console.log('onCopy:', this.availableSlotsText)
    },
    async createAdjustedMeeting() {
      console.log('createAdjustedMeeting')
    }
  }
}
</script>
