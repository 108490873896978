const conditionTemplates = [
  {
    label: '💻 平日',
    duration: 60,
    timeRange: {
      start: '10:00',
      end: '17:00'
    },
    dayOfWeek: [
      'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'
    ]
  },
  {
    label: '🔆 平日の朝',
    duration: 60,
    timeRange: {
      start: '07:00',
      end: '10:00'
    },
    dayOfWeek: [
      'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'
    ]
  },
  {
    label: '🏃 平日の午前中',
    duration: 60,
    timeRange: {
      start: '09:00',
      end: '12:00'
    },
    dayOfWeek: [
      'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'
    ]
  },
  {
    label: '🌮 平日の午後',
    duration: 60,
    timeRange: {
      start: '12:00',
      end: '17:00'
    },
    dayOfWeek: [
      'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'
    ]
  },
  {
    label: '🥩 平日の夜',
    duration: 60,
    timeRange: {
      start: '17:00',
      end: '22:00'
    },
    dayOfWeek: [
      'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'
    ]
  },
  {
    label: '🕺 フライデーナイト',
    duration: 60,
    timeRange: {
      start: '17:00',
      end: '22:00'
    },
    dayOfWeek: [
      'Friday'
    ]
  },
  {
    label: '⛺️ 週末',
    duration: 60,
    timeRange: {
      start: '10:00',
      end: '17:00'
    },
    dayOfWeek: [
      'Saturday', 'Sunday'
    ]
  },
  {
    label: '🔧 カスタム',
    duration: 60,
    timeRange: {
      start: '10:00',
      end: '17:00'
    },
    dayOfWeek: [
      'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'
    ]
  }
]

export { conditionTemplates }