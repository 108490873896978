<template lang="pug">
  div
    Header(:content="headerContent")
    div.wrap-calendar-meeting-settings
      div.pt20
        div.mb20
          ModuleEditMember(ref="edit_member"
            @validateCurrentSettings="validateCurrentSettings")
        div(v-show="showDetailSettings")
          ModuleSetCondition(ref="set_condition"
            @validateCurrentSettings="validateCurrentSettings")
          ModuleSelectMeetingRoomType(ref="select_meeting_room_type"
            @validateCurrentSettings="validateCurrentSettings")
          ModuleSetSummary(ref="set_summary"
            @validateCurrentSettings="validateCurrentSettings")
        
        div.wrap-toggle-btn.f.mt20
          div.f.fm
            span(@click="showDetailSettings = !showDetailSettings"
              ).btn-toggle-detail.fz14.cursor-pointer {{!showDetailSettings ? '詳細設定' : '詳細設定を閉じる'}}
            v-icon {{!showDetailSettings ? 'mdi-chevron-down' : 'mdi-chevron-up'}}

      div.wrap-send-btn.f.fh
        div(@click="onClose"
          :class="{'active': !preventNext}").btn-send.py16
          span.block.text-center.fz14 送信日時を選択する
    ModalWindow(:showModal="showTutorialWindow"
      @closeModalWindow="showTutorialWindow = false")
      ModuleTutorialCreateMeeting(:availableSlotsText="availableSlotsText"
        @closeModalWindow="showTutorialWindow = false")
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';
.wrap-calendar-meeting-settings {
  width: 100%;
  min-height: 100vh;
  padding-top: $header_height;
  .wrap-toggle-btn {
    width: $base_width_percent;
    max-width: $base_max_width_px;
    margin: 0 auto;
    span {
      color: '#999';
      // color: $active_color;
    }
  }
  .wrap-send-btn {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 12px;
    // z-index: 1001;
    .btn-send {
      width: $base_width_percent;
      max-width: $base_max_width_px;
      background: $deactive_color;
      border-radius: 28px;
      filter: $drop_shadow;
      span {
        color: $dark_primary_text_color;
      }
      &.active {
        background: $active_color;
        cursor: pointer;
      }
    }
  }
}
</style>

<style lang="scss">
@import '@/scss/_variables.scss';
.wrap-calendar-meeting-settings {
  .set-summary-content {
    .v-input__slot {
      border-bottom: $basic_border;
    }
  }
}
</style>

<script>
import database from '@/database'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')
import Header from '@/components/shared/Header'
import ModalWindow from '@/components/shared/ModalWindow'
import ModuleSetCondition from '@/components/module/create-meeting/ModuleSetCondition'
import ModuleSetSummary from '@/components/module/create-meeting/ModuleSetSummary'
import ModuleEditMember from '@/components/module/create-meeting/ModuleEditMember3'
import ModuleSelectMeetingRoomType from '@/components/module/create-meeting/ModuleSelectMeetingRoomType'
import ModuleTutorialCreateMeeting from '@/components/module/create-meeting/ModuleTutorialCreateMeeting'

export default {
  components: {
    Header,
    ModalWindow,
    ModuleSetCondition,
    ModuleSetSummary,
    ModuleEditMember,
    ModuleSelectMeetingRoomType,
    ModuleTutorialCreateMeeting
  },
  props: {
    selectMode: {
      type: String,
      required: true
    },
    calendarSlots: {
      type: Array,
      required: true
    },
    conditionSettings: {
      type: Object,
      required: true
    },
    newMeetingDocId: {
      type: String,
      required: true
    },
    availableSlotsText: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      headerContent: {
        title: 'ミーティングの設定',
        left: {
          icon: 'mdi-close',
          action: this.onClose
        }
      },
      user: null,
      showDetailSettings: false,
      showTutorialWindow: false,
      preventNext: true
    }
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  async created() {
    if (this.selectMode === 'start-adjusting-by-sending-available-slots') {
      this.user = await database.userCollection().findById(this.uid)

      if (!this.user.completeTutorialInCreateMeeting) {
        this.showTutorialWindow = true
      }

      this.$root.$emit('showSharedMessage', {
        message: '選択した空き日時がコピーされました！',
        icon: 'mdi-content-copy'
      })
    }
  },
  methods: {
    validateCurrentSettings() {
      console.log('validateCurrentSettings:')
      const moduleSetSummary = this.$refs.set_summary
      const moduleEditMember = this.$refs.edit_member
      if (
        moduleEditMember.getMember()[0] &&
        moduleEditMember.allMemberIsValid() &&
        moduleSetSummary.title !== '' &&
        moduleSetSummary.title !== null
      ) {
        this.preventNext = false
      } else {
        this.preventNext = true
      }
    },
    onClose() {
      this.$emit('closeModalPage')
    }
  }
}
</script>
