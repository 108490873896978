<template lang="pug">
  div.wrap-module-create-meeting.mt20
    div.wrap-condition-templates.condition-content.mb20
      v-select(label="ミーティングの条件"
        v-model="currentConditionTemplate"
        :items="conditionTemplates"
        item-text="label").mb6
      div(v-if="!showDetailSettings").f.flex-right
        div(@click="showDetailSettings = !showDetailSettings").f.fm
          v-icon(v-if="!showDetailSettings" color="#4285f4") mdi-chevron-down
          span.btn-adjust-condition.cursor-pointer.fz12 条件を微調整する
    div(v-show="showDetailSettings").create-meeting-content.condition-content
      div.wrap-date.mb18
        ItemDateRangePicker(ref="dateRangePicker"
          :dateRange="dateRange"
          @updateDateRange="updateDateRange")
      div.wrap-time.mb18
        ItemTimePicker(ref="timePicker"
          label="候補時間の範囲"
          :range="timeRange"
          @updateTimeRange="updateTimeRange")
      div.wrap-day-of-week.mb8
        span.label.block.mb8 曜日の候補条件
        div.f.flex-wrap
          span(v-for="item in dayOfWeek"
            @click="item.active = !item.active"
            :class="{'active': item.active}"
            :key="item.day").selection.fz14.px16.pt7.pb5.mr6.mb6 {{item.label}}
    div(v-show="showDetailSettings").condition-content.f.flex-right.mb16
      div(@click="showDetailSettings = !showDetailSettings").f.fm
        v-icon(color="#4285f4") mdi-chevron-up
        span.btn-adjust-condition.cursor-pointer.fz12 閉じる
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.wrap-module-create-meeting {
  width: 100%;
  // min-height: 100vh;
  // padding-top: $header_height;
  .condition-content {
    width: $base_width_percent;
    max-width: $base_max_width_px;
    margin: 0 auto;
    .label {
      color: #777;
    }
    .condition-text {
      color: #777;
    }
  }
  .btn-adjust-condition {
    color: $active_color;
  }
  .create-meeting-content {
    .wrap-date-range {
      width: 186px;
    }
    .wrap-day-of-week {
      .label {
        font-size: 12px;
        color: #777;
      }
      .selection {
        border: solid 0.6px #777;
        border-radius: 12px;
        color: #777;
        cursor: pointer;
        &.active {
          color: #fff;
          background: $active_color;
          border: solid 0.6px $active_color;
        }
      }
    }
  }
  .wrap-condition-text {
    span {
      color: $secondary_text_color;
    }
  }
}
</style>

<style lang="scss">
.wrap-module-create-meeting {
  .v-input--selection-controls {
    margin: 0;
  }
}
</style>

<script>
import { conditionTemplates } from './conditionTemplates'
import format from 'date-fns/format'
import { addDays, endOfDay } from 'date-fns'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
import ItemTimePicker from '@/components/item/ItemTimePicker2'
import ItemDateRangePicker from '@/components/item/ItemDateRangePicker'

export default {
  components: {
    ItemTimePicker,
    ItemDateRangePicker
  },
  computed: {
    ...mapStateAuth(['uid']),
    conditionTemplates() {
      return conditionTemplates
    }
  },
  watch: {
    dateRange() {
      this.validate()
    },
    timeRange() {
      this.validate()
    },
    dayOfWeek: {
      handler() {
        this.validate()
      },
      deep: true
    },
    currentConditionTemplate() {
      const tmp = this.conditionTemplates.filter(
        (e) => e.label === this.currentConditionTemplate
      )[0]
      this.timeRange = tmp.timeRange
      this.dayOfWeek = this.dayOfWeek.map((e) => {
        e.active = tmp.dayOfWeek.includes(e.day)
        return e
      })

      this.$emit('setSummary', {
        title: `${this.currentConditionTemplate}のミーティング`,
        about: ''
      })
    }
  },
  data() {
    return {
      timeRange: {
        start: '10:00',
        end: '17:00'
      },
      dateRange: null,
      dayOfWeek: [
        { label: '月', day: 'Monday', active: true },
        { label: '火', day: 'Tuesday', active: true },
        { label: '水', day: 'Wednesday', active: true },
        { label: '木', day: 'Thursday', active: true },
        { label: '金', day: 'Friday', active: true },
        { label: '土', day: 'Saturday', active: false },
        { label: '日', day: 'Sunday', active: false }
      ],
      currentConditionTemplate: null,
      showDetailSettings: false
    }
  },
  created() {
    // this.dateRange = this.dateRangeSelections[1]
    const today = new Date()
    this.dateRange = {
      start: today,
      end: endOfDay(addDays(today, 14 - 1))
    }
    this.currentConditionTemplate = this.conditionTemplates[0].label
  },
  methods: {
    updateTimeRange(timeRange) {
      this.timeRange = timeRange
    },
    updateDateRange(dateRange) {
      this.dateRange = dateRange
    },
    validate() {
      this.$emit('validateCurrentSettings')
    }
  }
}
</script>
